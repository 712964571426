




















import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import LineChartData from './modelsLine';

@Component
export default class FormBar extends FormBase {
  data: LineChartData = {
    title: '',
    description: '',
    icon: '',
    seriesFields: [''],
    xAxisField: '',
    colors: [''],
  };
}
